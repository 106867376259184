// Colors
$light-gray: #96989c;
$dark-gray: #1c1d1f;
$gray: #343537;
$accent: #ff5b0f;

// Spacing
$s-size: 1.6rem;
$m-size: 2rem;
$l-size: 3.2rem;
$xl-size: 7rem;
$mobile-breakpoint: 45rem;
