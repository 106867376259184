a.contact {
  color: $light-gray;
  opacity: 0.5;
}

h1 > span,
h2 > span {
  display: block;
  padding-top: 0.3rem;
}

h1 {
  .description {
    color: $accent;
    font-size: 3.5rem;
  }
}

h1 > a.contact:hover,
a.contact:hover h2 {
  opacity: 1;
  transition-duration: 0.5s;
}

a.contact:hover {
  text-decoration: none;
}

.row:last-child a.icon {
  width: 33% !important;
  display: inline-block;
  padding-left: 15px;
}

.row:last-child .col-md-6 {
  padding-left: 70px;
  margin-right: inherit;
  margin-left: auto;
}

.title {
  font-size: 6rem;
  line-height: 1;
}

.icon {
  opacity: 0.2;
  transition-duration: 0.5s;
}

.icon:hover {
  opacity: 1;
}

@media (max-width: 992px) {
  .row:last-child .col-md-6 {
    padding-left: 30px;
    margin-right: inherit;
    margin-left: auto;
  }
}

@media (max-width: 768px) {
  .row:last-child .col-md-6 {
    width: 38rem;
    padding-left: 40px;
    margin-right: inherit;
    margin-left: auto;
  }
}

@media (max-width: 576px) {
  .row:last-child .col-md-6 {
    width: 38rem;
    margin-right: inherit;
    margin-left: auto;
  }
}
