html {
  font-size: 62.5%;
}

body {
  background-color: $gray;
  color: $light-gray;
  font-family: Roboto, sans-serif;
  font-size: $m-size;
  font-weight: 300;
}

button {
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: auto;
  }
}

.active,
h1,
h2,
h3 {
  font-weight: 300;
}

h1,
h2 {
  font-size: $m-size;
}

h3 {
  font-size: $xl-size;
}

p {
  font-size: $s-size;
}

.wrapper {
  position: absolute;
  width: 800px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

p {
  margin: 0;
}

.blurActive {
  filter: blur(3px);
}

a {
  width: fit-content;
  margin: auto;
  cursor: pointer;

  &.contact {
    color: $light-gray;
  }
}

.icon {
  svg path,
  svg circle {
    fill: $light-gray;
  }
}

.inner-wrapper {
  background-color: rgba($color: #000000, $alpha: 0.8);
  height: 100%;
  width: 100%;
  position: absolute;

  h1 {
    color: $light-gray;
    font-weight: 500 !important;
  }

  a {
    color: $light-gray;
    display: block;
    padding: 5px 10px;
    font-size: $s-size;

    &:hover {
      color: white;
      text-decoration: none;
      transition-duration: 500ms;
    }
  }
}

button {
  position: absolute;
  margin: 15px;
  right: 0;
  font-size: $m-size !important;
  color: $light-gray !important;
}

button:hover {
  text-decoration: none !important;
  color: white !important;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
  }

  .home .col-2 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
